<template>
    <div class="bg-green-600 text-white p-5 flex items-center">
        <a href="/">Вернутся на сайт</a>
        <div class="ml-auto">
            <slot name="actions"></slot>
        </div>
    </div>
    <div class="flex gap-8">
        <nav class="p-4">
            <ul>
                <li>
                    <a href="/admin/categories">
                    Категории
                    </a>
                </li>
                <li>
                    <a href="/admin/seeds">
                        Растения
                    </a>
                </li>
                <li>
                    <a href="/admin/brands">
                        Производители
                    </a>
                </li>
            </ul>
        </nav>
        <main class="p-8">
            <slot />
        </main>
    </div>
</template>